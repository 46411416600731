import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { OrganizationDropdownDto, OrganizationsApiService } from '@api-open';
import { AlertifyService, UserDataService } from '@shared/services';
import { AuthService } from '@shared/services/auth.service';
import { SAVED_ORGANIZATION } from '@shared/string-constants';

import { LocalStorageService } from '../local-storage.service';

const ALL_ORGANIZATION_ID = -1;

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  organizations$ = new BehaviorSubject<OrganizationDropdownDto[]>([]);
  organizationIdDisabled$: Observable<boolean>;

  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  private _selectedOrganization$: BehaviorSubject<number> = new BehaviorSubject<number>(null!);
  private _organizationIdDisabled$ = new BehaviorSubject(false);

  private _listenUntil$ = new Subject<void>();

  constructor(
    private organizationService: OrganizationsApiService,
    private localStorageService: LocalStorageService,
    private alertify: AlertifyService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private userDataService: UserDataService,
  ) {
    authService.logoutEvent$.subscribe(() => this.clean());

    this.organizationIdDisabled$ = this._organizationIdDisabled$.asObservable();
  }

  get selectedOrganization$(): Observable<number | null> {
    return this._selectedOrganization$.asObservable();
  }

  get selectedOrganization(): number | null {
    return this._selectedOrganization$.value;
  }

  init(): Observable<boolean> {
    // first before show page we need to load organization
    const organizations$ = this.getOrganizations().pipe(
      tap((organizations) => {
        this.organizations$.next(organizations);
      }),
    );
    // 3. listen if navigation with query params will occur
    // 2 organization was before, demo organization id is stored in getCurrentUser we can get in any time
    // 3.link has query params with organization we must change it in select and in local storage
    // 4. link has not query params with organization we must read local storage or choose default
    organizations$
      .pipe(
        switchMap(() =>
          this.listenQueryParams().pipe(
            map((value) => {
              const toSave = value ? value : this.chooseDefaultOrganizationId();
              this.selectOrganizationSaveToStore(toSave);
              this._organizationIdDisabled$.next(false);
              return EMPTY;
            }),
          ),
        ),
        takeUntil(this._listenUntil$),
      )
      .subscribe();

    return organizations$.pipe(
      map(() => true),
      catchError(() => {
        this.alertify.negative('Taikun could not load organizations please reload the page!');
        return of(true);
      }),
    );
  }

  initWithoutOrganizationSelect(): Observable<boolean> {
    this.organizations$.next([]);
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this._selectedOrganization$.next(undefined!);

    return of(true);
  }

  getOrganizations(partnerId?: number): Observable<OrganizationDropdownDto[]> {
    return this.organizationService.organizationsOrganizationList({ partnerId }).pipe(shareReplay(1));
  }

  updateOrganizations(): void {
    this.getOrganizations().subscribe((organizations) => {
      this.organizations$.next(organizations);
    });
  }

  selectOrganizationSaveToStore(organization: number): void {
    const org = JSON.stringify(organization ?? ALL_ORGANIZATION_ID);
    this.localStorageService.setItem(this.createOrganizationToken(), org);
    this.emitEvent(organization);
  }

  changeOrganization(organizationId: number): void {
    this.selectOrganizationSaveToStore(organizationId);
    this.clearQueryParams();
  }

  selectOrganization(organization: number): void {
    this.emitEvent(organization);
  }

  emitEvent(organization: number): void {
    const organizationExternal = this.prepareExternalValue(organization);
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this._selectedOrganization$.next(organizationExternal!);
  }

  listenQueryParams(): Observable<number> {
    return this.activatedRoute.queryParamMap.pipe(
      map((params) => {
        const organizationId = params.get('organizationId');
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        return organizationId ? +organizationId : null!;
      }),
    );
  }

  clearQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        organizationId: null,
        organizationName: null,
        projectId: null,
        kubernetesProfileId: null,
        userId: null,
        cloudId: null,
        accessProfileId: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private chooseDefaultOrganizationId(): number {
    const org = this.localStorageService.getItem(this.createOrganizationToken());
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    const organizationStored: number = org ? (JSON.parse(org) as number) : null!;
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return organizationStored ? organizationStored : this.userDataService.currentUser$.getValue().data!.organizationId!;
  }

  private prepareExternalValue(selectedOrganizationId: number): number | undefined {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return selectedOrganizationId === ALL_ORGANIZATION_ID ? null! : selectedOrganizationId;
  }

  private createOrganizationToken(): string {
    return `${SAVED_ORGANIZATION}__${this.authService.getDecodedToken().nameid}`;
  }

  private clean(): void {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.organizations$.next(null!);
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this._organizationIdDisabled$.next(null!);
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this._selectedOrganization$.next(null!);
    this._listenUntil$.next();
  }
}
